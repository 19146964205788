// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from 'firebase/auth'; // Import the specific auth providers

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAaViAET0c8tkISnfj-y37R8_Jd6M7KE1w",
    authDomain: "notes-app-8cba8.firebaseapp.com",
    databaseURL: "https://notes-app-8cba8-default-rtdb.firebaseio.com",
    projectId: "notes-app-8cba8",
    storageBucket: "notes-app-8cba8.appspot.com",
    messagingSenderId: "455945687187",
    appId: "1:455945687187:web:3fdbefff270323b641fd66",
    measurementId: "G-LLN4X3NTYM"
  };

  // Initialize Firebase only if there isn't an instance already
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  // Export the auth service and any other services you might use
  const auth = firebase.auth();
  
  // Export the Firebase app and auth instances for use in your application
  export { firebase, auth };

//   firebase.initializeApp(firebaseConfig);
// //   const firebaseApp = initializeApp(firebaseConfig);
//   // Get a reference to the auth service
// //   const auth = getAuth(firebaseApp);
//   // Example sign-in configuration
//   const uiConfig = {
//       signInFlow: 'popup',
//       signInOptions: [
//         GoogleAuthProvider.PROVIDER_ID,
//         FacebookAuthProvider.PROVIDER_ID,
//         EmailAuthProvider.PROVIDER_ID,
//         // Add other provider IDs as needed
//       ],
//       callbacks: {
//         signInSuccessWithAuthResult: () => false,
//       },
//   };
  
//   export { firebaseApp, auth, uiConfig };