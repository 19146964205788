import React, { useEffect } from 'react';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
// import './signinscreen.css';
import 'firebaseui/dist/firebaseui.css';

const uiConfig = {
    // The URL where users are redirected after sign-in.
    // Could be a callback route in your app.
    signInSuccessUrl: '/signedIn',
  
    // Set to 'popup' or 'redirect'. 'popup' is a common choice for SPA.
    signInFlow: 'popup',
  
    // Your FirebaseUI configuration goes here.
    signInOptions: [
      // Specify the authentication providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
  
    //   // This method shows how you can set specific options for individual providers.
    //   {
    //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //     requireDisplayName: true,
    //   },
    ],
  
    // Enables or disables FirebaseUI's accountchooser.com feature.
    // Recommended to be set to false to avoid issues on mobile devices and GDPR concerns.
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  
    // Custom callbacks for authentication.
    callbacks: {
      // Avoid redirects after sign-in by returning false.
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        // User successfully signed in.
        // You can access the authResult or redirectUrl parameters
        // depending on your specific needs.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
    },
  };
  
  const SignInScreen = () => {
    useEffect(() => {
      // Assuming Firebase has been initialized elsewhere
      if (!firebaseui.auth.AuthUI.getInstance()) {
        const ui = new firebaseui.auth.AuthUI(firebase.auth());
        ui.start('#firebaseui-auth-container', uiConfig);
      }
    }, []);
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', background: 'black' }}>
        <h1 style={{ color: '#444', marginBottom: '20px' }}>Sign In to manage your tasks</h1>
        <div id="firebaseui-auth-container" style={{ width: '100%', maxWidth: '320px' }}></div>
       </div>
    );
  };
  
  export default SignInScreen;