import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sentence from './Sentence';
import theme from './theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.png'; // Import the custom theme
import { TextField, Button, Box, Grid, ThemeProvider, CssBaseline, Typography, IconButton } from '@mui/material'; // Your custom theme with typewriter font
import IosShareIcon from '@mui/icons-material/IosShare';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import saveNotesToStorage from './saveNotes'; // Adjust the import path to where your function is
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import ClassIcon from '@mui/icons-material/Class';
import { auth } from './firebaseconfig'; // Ensure Firebase is initialized here
import SignInScreen from './signin';
import Logout from './logout';
// import { fetchUserNotes } from './saveNotes';
import NotesDialog from './NotesDropDown';

const App = () => {

  const [input, setInput] = useState('');
  const [paragraphs, setParagraphs] = useState([]);
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    // This effect listens for changes in the auth state (sign in or sign out).
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);


  const handleInputChange = (e) => setInput(e.target.value);
  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://1o0w01l8l4.execute-api.us-east-2.amazonaws.com/dev/getTasks', { prompt: input });
      console.log(response.data);
      const bodyArray = JSON.parse(response.data.body);
      const initialSentences = bodyArray.map(text => ({
        text,
        id: Math.random(),
        children: [],
        isExpanded: false,
        checked: false // Add checked property
      }));
      setParagraphs(initialSentences);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleSaveClick = () => {
    const flattenedTasks = flattenTasks(paragraphs);
    console.log(flattenedTasks);
    const flattenedNotes = flattenedTasks.map(task => (task.checked) + ' ' + task.text).join('\n');
    const fileName = "notesFileName"; // Determine the file name
    saveNotesToStorage(flattenedNotes, fileName).then(() => {
      // Handle the success scenario
      console.log('Notes were saved successfully');
    }).catch((error) => {
      // Handle any errors here
      console.error('Failed to save notes', error);
    });
  };

  const convertListToPlainText = (sentences) => {
    return sentences.map(sentence => {
      const status = sentence.checked ? '[x]' : '[ ]'; // Use '[x]' for checked and '[ ]' for unchecked
      return `${status} ${sentence.text}`;
    }).join('\n');
  };


  const handleExportText = () => {
    console.log(paragraphs);
    const flattenedTasks = flattenTasks(paragraphs);
    console.log(flattenedTasks);
    const plainText = flattenedTasks.map(task => (task.checked) + ' ' + task.text).join('\n');
    const element = document.createElement('a');
    const file = new Blob([plainText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'tasks.txt';
    document.body.appendChild(element);
    element.click();
  };


    // Function to recursively flatten nested tasks
    const flattenTasks = (sentences) => {
      let flattenedTasks = [];
      sentences.forEach((sentence) => {
        flattenedTasks.push({ text: sentence.text, checked:  (sentence.checked ? '[x]' : '[ ]') });
        console.log(sentence.checked);
        if (sentence.children.length > 0) {
          const childrenTasks = flattenTasks(sentence.children);
          flattenedTasks = flattenedTasks.concat(childrenTasks);
        }
      });
      return flattenedTasks;
    };
  // Recursive function to remove a sentence based on its id
  const removeSentenceById = (sentences, idToRemove) => sentences.reduce((acc, sentence) => {
    if (sentence.id === idToRemove) return acc; // Skip this sentence
    const updatedSentence = { ...sentence, children: removeSentenceById(sentence.children, idToRemove) };
    return [...acc, updatedSentence];
  }, []);

  const handleDismiss = (id) => {
    setParagraphs(prev => removeSentenceById(prev, id));
  };

  const expandSentence = (sentences, idToExpand, expandedSentences) => {
    return sentences.map(sentence => {
      if (sentence.id === idToExpand && !sentence.isExpanded) {
        const newChildren = Array.isArray(expandedSentences) ? expandedSentences.map(text => ({
          text,
          id: Math.random(),
          children: [],
          isExpanded: false
        })) : [];
        return { ...sentence, children: sentence.children.concat(newChildren), isExpanded: true };
      } else if (sentence.children.length > 0) {
        return { ...sentence, children: expandSentence(sentence.children, idToExpand, expandedSentences) };
      }
      return sentence;
    });
  };
  
  const addExpandedSentences = (sentences, idToExpand, expandedSentences) => {
    return sentences.map(sentence => {
      if (sentence.id === idToExpand) {
        const newChildren = Array.isArray(expandedSentences) ? expandedSentences.map(text => ({
          text,
          id: Math.random(),
          children: []
        })) : [];
        return { ...sentence, children: sentence.children.concat(newChildren) };
      }
      // Recurse into children if not the target sentence
      if (sentence.children.length > 0) {
        return { ...sentence, children: addExpandedSentences(sentence.children, idToExpand, expandedSentences) };
      }
      return sentence;
    });
  };
  const handleExpand = async (id, sentenceText) => {
    try {
      const response = await axios.post('https://1o0w01l8l4.execute-api.us-east-2.amazonaws.com/dev/getTasks', { prompt: sentenceText });
      const expandedSentences = JSON.parse(response.data.body);
      console.log("expandedSentences", expandedSentences);
      setParagraphs(prevParagraphs => expandSentence(prevParagraphs, id, expandedSentences));
    } catch (error) {
      console.error('Error expanding sentence:', error);
    }
  };

  const handleExpandWithInput = (id, inputValue) => {
  // Update the state or perform actions with inputValue here
  // Then call the original handleExpand
  handleExpand(id, inputValue);
};

  const renderSentences = (sentences, depth = 0) => {
    return sentences.map((sentence) => (
      <div key={sentence.id} style={{ marginLeft: `${depth * 20}px`, marginTop: '0px' }}>
        <Sentence
          sentence={sentence}
          onDismiss={() => handleDismiss(sentence.id)}
          onExpand={handleExpandWithInput}
          onCheck={() => handleCheck(sentence.id)} // Pass onCheck function
        />
        {sentence.children.length > 0 && renderSentences(sentence.children, depth + 1)}
      </div>
    ));
  };



  const handleCheck = (id) => {
    setParagraphs(prev => {
      return prev.map(sentence => {
        if (sentence.id === id) {
          return { ...sentence, checked: !sentence.checked };
        }
        return sentence;
      });
    });
  };

  if (!user) {
    // Display the SignInScreen if no user is authenticated
    return <SignInScreen />;
  }


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ bgcolor: 'black', color: 'white', minHeight: '100vh' }}>
          {!user && <SignInScreen />}
            <Box sx={{ p:2, paddingTop: 5 }}>
              <TextField
                fullWidth
                // label="Enter Text"
                variant="outlined"
                value={input}
                onChange={handleInputChange}
                InputLabelProps={{ style: { color: 'white' } }}
                sx={{
                  input: { color: 'white' },
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
                  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: "white" },
                  marginBottom: '10px'
                }}
              />

              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'darkgrey',
                  },
                }}
              >
                Break it Down
              </Button>
            </Box>
            <Box sx={{ paddingTop: 2 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                Enter a task to generate subtasks
              </Typography>
              <Typography sx={{ mb: 1 }}>
                - Hover on a task to see options.
              </Typography>
              <Typography sx={{ mb: 1 }}>
                - Click to expand each subtask.
              </Typography>
              <Typography sx={{ mb: 1 }}>
                - Click on dismiss to remove it.
              </Typography>
              <Typography sx={{ mb: 1 }}>
                - Click the checkbox to mark completed.
              </Typography>
            </Box>
            <Box sx={{ display:'flex', flexDirection: 'row' , justifyContent: 'space-between', position: 'absolute', bottom: 20, left: 10}}>
              <img src={logo} alt="Logo" style={{ width: 200 }} />
<NotesDialog> </NotesDialog>
          <Logout></Logout>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ overflowY: 'auto', maxHeight: '100vh'}}>
            <Box sx={{paddingTop: 3}}>
             {renderSentences(paragraphs)}
             </Box>
          </Grid>
        </Grid>
        <Box sx={{ position: 'absolute', bottom: '10vh', right: '10vh', zIndex: 1000 }}>
        <IconButton
            variant="contained"
            onClick={handleSaveClick}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'darkgrey',
              },
            }}
          >
        
            <ClassIcon></ClassIcon>
          </IconButton>
          <IconButton
            variant="contained"
            onClick={handleExportText}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'darkgrey',
              },
            }}
          >
        
            <IosShareIcon></IosShareIcon>
          </IconButton>
        </Box>

      </Box>
    </ThemeProvider>
  );
};

export default App;