import React, { useState, useRef, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import QueueIcon from '@mui/icons-material/Queue';
import TextField from '@mui/material/TextField'; // Import TextField for input
import Button from '@mui/material/Button'; // Import Button for submitting the input
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Sentence = ({ sentence, onDismiss, onExpand, onCheck }) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null); // Ref for the input wrapper

    const handleCheckChange = () => {
        onCheck(sentence.id);
    };

    // Toggle visibility of input field
    const toggleInputField = () => {
        setShowInput(!showInput);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    // Handle input submission
    const handleInputSubmit = () => {
        if (inputValue.trim() !== '') {
            onExpand(sentence.id, inputValue);
            setShowInput(false); // Hide input field after submission
            setInputValue(''); // Reset input field
        }
    };

    // Handle input submission
    const handleInputCancel = () => {
        setShowInput(false); // Hide input field after submission
    };



    useEffect(() => {
        // Detect clicks outside of the inputRef element
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowInput(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                flexWrap: 'wrap',
                marginBottom: 0,
                '&:hover .expand-btn, &:hover .dismiss-btn': {
                    opacity: 1,
                },
            }}
        >
            <Checkbox
                checked={sentence.checked}
                onChange={handleCheckChange}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                sx={{
                    color: '#000000',
                    '&.Mui-checked': {
                        color: '#000000',
                    },
                    '&.MuiIconButton-root:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            />
            <Typography
                variant="body1"
                sx={{
                    display: 'inline',
                    color: 'common.black',
                    marginBottom: 0,
                }}
            >
                {sentence.text}
            </Typography>
            {!sentence.isExpanded && (
                <>
                    <IconButton
                        className="expand-btn"
                        onClick={toggleInputField}
                        size="small"
                        sx={{ opacity: 0, transition: 'opacity 300ms' }}
                    >
                        <QueueIcon />
                    </IconButton>
                    {showInput && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                size="small"
                                value={inputValue}
                                onChange={handleInputChange}
                                variant="outlined"
                                sx={{
                                    width: 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '0px', // Removes rounded edges
                                        '& fieldset': {
                                            borderColor: 'black', // Sets border color to black
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'black', // Sets border color to black on hover
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'black', // Sets border color to black when focused
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        color: 'black', // Sets text color to black
                                    },
                                }}
                            />
                            <Button
                                onClick={handleInputSubmit}
                                variant="contained"
                                sx={{
                                    backgroundColor: 'black', // Sets button color to black
                                    color: 'white', // Sets text color to white for contrast
                                    '&:hover': {
                                        backgroundColor: 'black', // Ensures the button remains black on hover
                                    },
                                    borderRadius: '0px', // Removes rounded edges from the button
                                }}
                                size="small"
                            >
                                Generate sub tasks
                            </Button>
                            <IconButton
                                className="dismiss-btn"
                                onClick={handleInputCancel}
                                color="error"
                                size="small"
                                sx={{ opacity: 0, transition: 'opacity 300ms', color: 'black' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}
                </>
            )}
            {!showInput && (
                <IconButton
                    className="dismiss-btn"
                    onClick={() => onDismiss(sentence.id)}
                    color="error"
                    size="small"
                    sx={{ opacity: 0, transition: 'opacity 300ms', color:'gray' }}
                >
                    <RemoveCircleIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default Sentence;