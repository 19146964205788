import React from 'react';
// import { useNavigate } from 'react-router-dom'; // For navigation after logout
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import IconButton from '@mui/material/IconButton';

const Logout = () => {
//   let navigate = useNavigate(); // Hook for navigation

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      // Redirect to login screen or home page after logout
    //   navigate('/login'); // Adjust the path as necessary
    } catch (error) {
      console.error('Logout Error:', error);
      alert('Failed to log out. Please try again.');
    }
  };

  return (
    <IconButton
      onClick={handleLogout}
      color="error" // You can adjust the color as needed
      size="small"
      sx={{ transition: 'opacity 300ms', color: 'white' }} // Customize as needed
      aria-label="logout"
    >
      <PowerSettingsNewIcon />
    </IconButton>
  );
};

export default Logout;