import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const saveNotesToStorage = async (flattenedNotes, fileName) => {
  const user = firebase.auth().currentUser;

  if (!user) {
    console.log('No user authenticated.');
    alert('You need to be logged in to save notes.');
    return;
  }

    fileName = prompt("Please enter a name for your note:", "MyNote");
  if (fileName === null || fileName === "") {
    console.log('Note saving cancelled.');
    alert('Note saving cancelled.');
    return; // User cancelled the prompt
  }

  const notesPath = `notes/${user.uid}/${fileName}.txt`;
  const storageRef = firebase.storage().ref(notesPath);

  const blob = new Blob([flattenedNotes], { type: 'text/plain' });

  storageRef.put(blob).then((snapshot) => {
    console.log('Uploaded notes blob!', snapshot);
    alert('Notes saved successfully!');
  }).catch((error) => {
    console.error('Error uploading notes blob:', error);
    alert('Failed to save notes.');
  });
};

export const fetchUserNotes = async () => {
    const user = firebase.auth().currentUser;
  
    if (!user) {
      console.log('No user authenticated.');
      alert('You need to be logged in to fetch notes.');
      return [];
    }
  
    const notesPath = `notes/${user.uid}/`;
    const storageRef = firebase.storage().ref(notesPath);
    
    try {
      // List all items in the user's notes directory
      const result = await storageRef.listAll();
      console.log(result);
      const noteFiles = result.items;
  
      // Map over the array of StorageReference objects to get the download URLs
      const noteUrls = await Promise.all(
        noteFiles.map(async fileRef => ({
            name: fileRef.name,
            url: await fileRef.getDownloadURL(),
          }))
      );
  
      console.log('Fetched note URLs:', noteUrls);
      return noteUrls; // Returns an array of URLs pointing to the notes
    } catch (error) {
      console.error('Error fetching user notes:', error);
      alert('Failed to fetch notes.');
      return [];
    }
  };  

export default saveNotesToStorage;