import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CircularProgress from '@mui/material/CircularProgress'; // For loading indicator
import { fetchUserNotes } from './saveNotes'; // Adjust the path
import { Typography, Box } from '@mui/material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const NotesDialog = () => {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading status

    // Function to handle note deletion
    const deleteNote = async (noteName) => {
        const user = firebase.auth().currentUser;
        if (!user) {
          alert('You must be logged in to delete notes.');
          return;
        }
    
        const notePath = `notes/${user.uid}/${noteName}`;
        const storageRef = firebase.storage().ref(notePath);
    
        try {
          await storageRef.delete();
          alert(`${noteName} has been deleted.`);
          setNotes(notes.filter(note => note.name !== noteName)); // Update UI after deletion
        } catch (error) {
          console.error('Error deleting note:', error);
          alert('Failed to delete note.');
        }
      };

      
  const handleOpen = async () => {

    setLoading(true); // Start loading
    const fetchedNotes = await fetchUserNotes();
    console.log(fetchedNotes);
    setNotes(fetchedNotes);
    console.log("fetched notes", notes);
    setLoading(false); // End loading
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ color: 'white', '&:hover': { backgroundColor: 'black' } }}>
        <DescriptionOutlinedIcon />
      </IconButton>
      <Dialog 
        open={open} 
        onClose={handleClose}
        // Adjust size with the sx prop
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '60%', // Minimum width of the dialog
            maxWidth: '60%', // Maximum width, feel free to adjust
            borderRadius: 0,
            position: 'absolute',
            right: '10%',
            border: '3px solid black'
          },
        }}
      >
        <DialogTitle>Saved Notes</DialogTitle>
        <DialogContent>
        <Box sx={{
      display: 'flex',
      flexDirection: 'column', // Stack notes vertically
      alignItems: 'flex-start', // Align items to the start of the container
      gap: '20px', // Space between each note item
    }}>
          {loading ? (
            <CircularProgress /> // Show loading indicator while fetching notes
          ) : (
            notes.map((note, index) => (
              <div key={index}>
    <Box component="a" target="_blank" rel="noopener noreferrer" sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: '10px', 
      textDecoration: 'none', 
      color: 'inherit'
    }}>
      <TextSnippetIcon />
      <Box component="a" href={note.url} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit' }}>
                {note.name}
              </Box>
              <IconButton         onClick={(event) => {
          event.stopPropagation(); // This is crucial
          deleteNote(note.name);
        }} sx={{ color: 'black' }}>
                <DeleteIcon />
              </IconButton>
    </Box>
                {/* Optional: Add a link or button to view the note */}
              </div>
            ))
          )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotesDialog;